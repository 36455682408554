import { CommonModule } from '@angular/common'
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { AuthConfig, AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular'
import { CedarPublicApiModule, Configuration, ConfigurationParameters } from '@cts/cedar-public-api'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { NgHttpLoaderModule } from 'ng-http-loader'
import { BaseChartDirective, provideCharts, withDefaultRegisterables } from 'ng2-charts'
import { APP_CONFIG as config, AppConfig } from 'projects/cedar-shared/src/app-config/app-config'
import { API_BASE_PATH } from 'projects/cedar-shared/src/interceptors/API_BASE_PATH'
import { ApiErrorInterceptor } from 'projects/cedar-shared/src/interceptors/api-error.interceptor'
import { ImpersonationInterceptor } from 'projects/cedar-shared/src/interceptors/impersonation.interceptor'
import { OptionalJwtInterceptor } from 'projects/cedar-shared/src/interceptors/optional-jwt.interceptor'

import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing/app-routing.module'
import { LatestDataComponent } from './components/latest-data/latest-data.component'
import { LineChartComponent } from './components/line-chart/line-chart.component'
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component'
import { MenuBarComponent } from './components/menu-bar/menu-bar.component'
import { PageNotAllowedComponent } from './components/page-not-allowed/page-not-allowed.component'
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component'
import { ServerErrorComponent } from './components/server-error/server-error.component'
import { LoadingDirective } from './directives/loading.directive'
import { ReleaseVersionInterceptor } from './services/release-version.interceptor'

export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    basePath: config.publicApiBasePath
  }
  console.log(params)
  return new Configuration(params)
}

var authConfig: AuthConfig = {
  ...config.authentication,
  httpInterceptor: {
    allowedList: [{ uriMatcher: (url) => url.startsWith(config.publicApiBasePath) }]
  }
}

@NgModule({
  imports: [
    CedarPublicApiModule.forRoot(apiConfigFactory),
    AuthModule.forRoot(authConfig),
    AppRoutingModule,
    NgbModule,
    CommonModule,
    BrowserModule,
    NgHttpLoaderModule.forRoot(),
    BaseChartDirective
  ],
  declarations: [
    AppComponent,
    MenuBarComponent,
    LatestDataComponent,
    LoadingDirective,
    PageNotFoundComponent,
    PageNotAllowedComponent,
    LoadingDirective,
    LoadingSpinnerComponent,
    ServerErrorComponent,
    LineChartComponent
  ],
  exports: [NgHttpLoaderModule],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OptionalJwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useExisting: ImpersonationInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useExisting: ReleaseVersionInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useExisting: ApiErrorInterceptor,
      multi: true
    },
    {
      provide: API_BASE_PATH,
      useValue: config.publicApiBasePath
    },
    {
      provide: AppConfig,
      useValue: config
    },
    {
      provide: AuthHttpInterceptor
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideCharts(withDefaultRegisterables())
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
