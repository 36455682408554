<div class="relative min-h-screen bg-white flex flex-col">
  <app-menu-bar></app-menu-bar>

  <main class="flex-1">
    <router-outlet>
      <ng-http-loader></ng-http-loader>
    </router-outlet>
  </main>

  <footer class="main-wrapper bg-secondary-100 h-[72px] flex justify-between items-center">
    <span class="text-sm">CTS &#64; 2023. All rights reserved.</span>
    <div class="flex gap-2">
      <a href="">
        <img
          class="w-fit text-end rem:h-[20px]"
          src="../assets/fonts/icon-facebook.svg"
          alt="icon" />
      </a>
      <a href="">
        <img
          class="w-fit text-end rem:h-[20px]"
          src="../assets/fonts/icon-linkedin.svg"
          alt="icon" />
      </a>
    </div>
  </footer>
</div>
