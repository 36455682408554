<div class="fixed top-0 left-0 w-full z-[100] bg-white">
  <div class="relative w-full">
    <header class="px-6 rem:h-[72px] flex items-center justify-between border-b border-opacity-20">
      <div class="flex gap-20 items-center rem:h-[72px]">
        <a href="/">
          <img class="h-14" src="../assets/fonts/logo.svg" alt="Main Logo" />
        </a>
        <div class="hidden lg:block">
          <nav>
            <ul class="flex">
              <ng-container *ngFor="let header of leftMenuItems">
                <li class="shrink-0" *ngIf="!header.children">
                  <button
                    type="button"
                    class="w-full rem:h-[72px] px-4 flex justify-center items-center flex-nowrap gap-2 text-sm font-medium leading-6 text-black border-b-2 border-transparent"
                    routerLinkActive="!border-black bg-secondary-300.5 text-secondary-500"
                    appSetAriaCurrentOnActiveLink
                    [routerLink]="header.url"
                    [queryParams]="header.queryParams">
                    <span>{{ header.title }}</span>
                  </button>
                </li>
                <li class="shrink-0" *ngIf="header.children">
                  <div class="relative group" #menuParent [routerLink]="[header.url]">
                    <a
                      routerLinkActive="!border-black bg-secondary-300.5 text-secondary-500"
                      (click)="toggleDropdown($event, menuParent)"
                      class="w-full rem:h-[72px] px-4 flex justify-center items-center flex-nowrap gap-2 text-sm font-medium leading-6 text-black border-b-2 border-transparent">
                      <span>{{ header.title }}</span>
                      <img
                        class="w-fit rem:h-[8px]"
                        src="../assets/fonts/icon-arrow-down.svg"
                        alt="icon" />
                    </a>

                    <div
                      [ngClass]="
                        isExpanded(menuParent)
                          ? 'transition east-out duration-300 transform opacity-100 scale-100 expanded'
                          : 'transition ease-in duration-75 transform opacity-0 scale-95 collapsed'
                      "
                      class="absolute left-0 bg-white p-1 space-y-1 min-w-[240px] border border-black w-full">
                      <ul>
                        <li *ngFor="let child of header.children">
                          <a
                            [routerLink]="child.url"
                            [queryParams]="child.queryParams"
                            class="p-2 h-10 flex items-center text-sm hover:bg-secondary-100">
                            <span>{{ child.title }} </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ng-container>
            </ul>
          </nav>
        </div>
      </div>

      <div class="gap-3 items-center hidden lg:flex">
        <ng-container *ngFor="let header of rightMenuItems">
          <div class="shrink-0" *ngIf="!header.children">
            <button
              type="button"
              class="w-full rem:h-[72px] px-4 flex justify-center items-center flex-nowrap gap-2 text-sm font-medium leading-6 text-black border-b-2 border-transparent"
              routerLinkActive="!border-black bg-secondary-300.5 text-secondary-500"
              appSetAriaCurrentOnActiveLink
              [routerLink]="header.url"
              [queryParams]="header.queryParams">
              <img
                *ngIf="header.image"
                class="w-fit rem:h-[16px]"
                src="../assets/fonts/{{ header.image }}"
                alt="icon" />
              <span>{{ header.title }}</span>
            </button>
          </div>
          <div class="shrink-0" *ngIf="header.children">
            <div class="relative group" #menuParent [routerLink]="[header.url]">
              <a
                routerLinkActive="!border-black bg-secondary-300.5 text-secondary-500"
                (click)="toggleDropdown($event, menuParent)"
                class="w-full rem:h-[72px] px-4 flex justify-center items-center flex-nowrap gap-2 text-sm font-medium leading-6 text-black border-b-2 border-transparent">
                <img
                  class="w-fit rem:h-[16px]"
                  src="../assets/fonts/{{ header.image }}"
                  alt="icon" />
                <span>{{ header.title }}</span>
              </a>

              <div
                [ngClass]="
                  isExpanded(menuParent)
                    ? 'transition east-out duration-300 transform opacity-100 scale-100 expanded'
                    : 'transition ease-in duration-75 transform opacity-0 scale-95 collapsed'
                "
                class="absolute left-0 bg-white p-1 space-y-1 min-w-[180px] border border-black w-full">
                <ul>
                  <li *ngFor="let child of header.children">
                    <a
                      [routerLink]="child.url"
                      [queryParams]="child.queryParams"
                      class="p-2 h-10 flex items-center text-sm hover:bg-secondary-100">
                      <span>{{ child.title }} </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </header>
  </div>
</div>
